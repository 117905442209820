import { matchPath } from 'react-router-dom'

import {
  TPermission,
  TPermissionParams,
  TPermissionRoutes,
} from '@/types/TPermission'

export const routesList: TPermissionRoutes[] = [
  {
    routes: ['/company/settings'],
    permissions: [
      'comp-1', // View Company
    ],
  },
  {
    routes: ['/company/settings'],
    permissions: [
      'comp-cred-1', // View Company Credentials
    ],
  },
  {
    routes: ['/customers'],
    permissions: [
      'cust-a-0', // List Customers
    ],
  },
  {
    routes: ['/customers/:id/payment-methods'],
    permissions: [
      'cust-ba-0', // List Customer Bank Accounts
      'cust-c-0', // List Customer Cards
      'cust-pm-sd-0', // List Suspended Payment Methods
    ],
  },
  {
    routes: ['/customers/:id/payment-methods/add'],
    permissions: [
      'cust-ba-2', // Add Customer Bank Account
      'cust-c-2', // Add Customer Card
    ],
  },
  {
    routes: ['/customers/:id/statements'],
    permissions: [
      'cust-sta-0', // List Customers Statements
    ],
  },
  {
    routes: ['/customers/:id/activity'],
    permissions: [
      'cust-act-0', // List Recent Activities
    ],
  },
  {
    routes: ['/customers/:id/payments'],
    permissions: [
      'cust-pay-0', // List Customer Payments
    ],
  },
  {
    routes: ['/customers/:id/statements/:statementId/invoices'],
    permissions: [
      'cust-pay-1', // View Invoices Transaction
    ],
  },
  {
    routes: ['/merchants'],
    permissions: [
      'merch-0', // List Merchants
    ],
  },
  {
    routes: ['merchants/:id'],
    permissions: [
      'merch-1', // View Merchant
    ],
  },
  {
    routes: ['merchants/:id/gateways'],
    permissions: [
      'merch-gate-0', // List Merchant Gateways
    ],
  },
  {
    routes: ['merchants/:id/config'],
    permissions: [
      'merch-conf-1', // View Merchant Configuration
    ],
  },
  {
    routes: ['/users'],
    permissions: [
      'members-0', // List Users
    ],
  },
  {
    routes: ['/users/capabilities'],
    permissions: [
      'cap-0', // List Capabilities
    ],
  },
  {
    routes: ['/users/groups'],
    permissions: [
      'cap-grp-0', // List Permission Groups
    ],
  },
  {
    routes: ['/payments'],
    permissions: [
      'pro-pay-0', // List Payments
    ],
  },
]

export const routeExists = (pathname: string) => {
  if (pathname === '/') return true

  return routesList
    .flatMap((item) => item.routes)
    .some((route) => matchPath(route, pathname))
}

export const validPermissionsRoute = (
  pathname: string,
  permissions: string[] = []
): boolean => {
  let valid = false

  routesList.map((routeList) => {
    routeList.routes.map((route) => {
      const match = matchPath(route, pathname)
      if (match && !valid) {
        valid = routeList.permissions.some((permission) =>
          permissions.includes(permission)
        )
      }
    })
  })

  return pathname === '/' ? true : valid
}

export const getPermissionsRoutes = ({
  isAdmin = false,
}: TPermissionParams): TPermission[] => {
  const items: TPermission[] = [
    {
      name: 'dashboard',
      description: 'Dashboard',
      path: `/`,
    },
  ]

  if (isAdmin) {
    items.push({
      name: 'administration',
      path: '/admin',
    })
  }

  return items
}
