import { TMerchantAccess } from '@/types/store/state'
import { ChangeEvent, useEffect, useState } from 'react'

type TUseSelectMerchantProps = {
  merchants: TMerchantAccess[]
  merchantActive: TMerchantAccess | TMerchantAccess[]
  isSelectOpened?: boolean
}

export const useSelectMerchant = ({
  merchants,
  merchantActive,
  isSelectOpened,
}: TUseSelectMerchantProps) => {
  const [searchValue, setSearchValue] = useState('')

  const merchantActives = Array.isArray(merchantActive)
    ? merchantActive
    : [merchantActive]

  const isSelected = (merchantId: string) =>
    merchantActives.some((merchant) => merchant.id === merchantId)

  const allSelected = merchants.every((merchant) =>
    merchantActives.some((active) => active.id === merchant.id)
  )

  const merchantsList = searchValue
    ? merchants.filter(({ name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase())
      )
    : merchants

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value)

  useEffect(() => {
    if (!isSelectOpened) {
      setSearchValue('')
    }
  }, [isSelectOpened])

  return {
    searchValue,
    allSelected,
    merchantsList,
    merchantActives,
    isSelected,
    handleSearch,
  }
}
