import { createContext, useContext, useReducer } from 'react'

// Reducers
import { configReducer, initialConfigState } from './reducers/configReducer'

// Types
import { TContextProps } from '@/types/TContext'
import { TConfig } from '@/types/store/state'

export const ConfigContext = createContext<TContextProps<TConfig>>([
  initialConfigState,
  () => undefined,
])

export type TConfigContextProps = {
  /**
   * Set initial state
   * @default initialConfigState
   */
  initialState?: TConfig
}
export const ConfigProvider: React.FC<TConfigContextProps> = ({
  children,
  initialState = initialConfigState,
}) => {
  const [state, dispatch] = useReducer(configReducer, initialState)

  return (
    <ConfigContext.Provider value={[state, dispatch]}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfigContext = () => useContext(ConfigContext)
