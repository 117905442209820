import { createContext, useContext, useReducer } from 'react'

// Reducers
import { authReducer, initialAuthState } from './reducers/authReducer'

// Types
import { TContextProps } from '@/types/TContext'
import { TAuth } from '@/types/store/state'

export const AuthContext = createContext<TContextProps<TAuth>>([
  initialAuthState,
  () => undefined,
])

export type TAuthContextProps = {
  /**
   * Set initial state
   * @default initialAuthState
   */
  initialState?: TAuth
}
export const AuthProvider: React.FC<TAuthContextProps> = ({
  children,
  initialState = initialAuthState,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
