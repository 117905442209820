import { matchPath } from 'react-router-dom'

const titleOptions: Record<string, string> = {
  '/': 'Dashboard',
  '/merchants': 'Merchants',
  '/merchants/:id': 'Merchant - Details',
  '/merchants/:id/gateways': 'Merchant - Gateway Integration',
  '/merchants/:id/config': 'Merchant - Configuration',
  '/merchants/:id/webhooks': 'Merchant - Webhooks',
  '/users': 'Users',
  '/payments': 'Payments',
  '/company/settings': 'Company - Details',
  '/company/credentials': 'Company - Credentials',
  '/account': 'Account Settings',
  '/account/password': 'Password Change',
}

export const getHeadTitle = (pathname: string) => {
  let route = pathname || ''

  if (!titleOptions[route]) {
    if (matchPath('/merchants/:id', route)) route = '/merchants/:id'
    if (matchPath('/merchants/:id/gateways', route))
      route = '/merchants/:id/gateways'
    if (matchPath('/merchants/:id/config', route))
      route = '/merchants/:id/config'
    if (matchPath('/merchants/:id/webhooks', route))
      route = '/merchants/:id/webhooks'
  }

  return titleOptions[route] || ''
}
