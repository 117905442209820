// Context
import { TAction } from '@/types/TContext'

// Constants
import { constants } from '@/context/constants'

// Types
import { TConfig } from '@/types/store/state'

export const initialConfigState: TConfig = {
  company_id: '',
  merchant_has_no_gateway: false,
  company_has_merchants: true,
}

export const configReducer = (
  state: TConfig,
  { type, payload }: TAction<any>
): TConfig => {
  switch (type) {
    case constants.SET_CONFIG: {
      const data: TConfig = payload
      return {
        ...state,
        ...data,
      }
    }
    case constants.SET_MERCHANTS_NOT_ASSIGNED: {
      return {
        ...state,
        merchant_has_no_gateway: payload as boolean,
      }
    }
    case constants.RESET_CONFIG: {
      return {
        ...initialConfigState,
      }
    }

    default:
      return state
  }
}
