import { useToasts } from '@matic.co/core'
import {
  IMAGE_FILE_FORMATS,
  IMAGE_SIZE,
  messageErrorRequest,
  requestWasAborted,
  sentryPrepareData,
} from '@matic.co/utils'
import * as Sentry from '@sentry/react'
import { add, getTime } from 'date-fns'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Services
import {
  controllerApi,
  getUserStatusRequest,
  profileDeletePhoto,
  profileUpdate,
  profileUpdatePhoto,
  refreshPermissionsRequest,
  refreshTokenRequest,
  signOutRequest,
} from '@/utils/services'

// Config
import { ACCOUNT_URL, DOMAIN_COOKIE, ENV_PROD } from '@/config/env'

// Context
import { useAuthContext } from '@/context/authContext'

// Actions
import { authActions } from '@/context/actions/authActions'

// Utils
import { getCookie, removeCookie, setCookie } from '@/utils/cookies'
import { routeExists, validPermissionsRoute } from '@/utils/guards/permissions'

// Types
import { TAccess, TMerchant, TMerchantAccess, TUser } from '@/types/store/state'

const routesNoGetSession = ['/404', '/under-construction', '/healthcheck']

const initialValues: TUser = {
  first_name: '',
  last_name: '',
  email: '',
  cell_phone: '',
}

const cleanTokenAndRedirect = (redirectHost = '') => {
  removeCookie('in_a')
  removeCookie('in_a_exp')
  removeCookie('cid', { noDomain: true })
  removeCookie('mid', { noDomain: true })

  Sentry.setUser(null)

  const domainRedirect = `${
    ACCOUNT_URL || 'https://account.matic.io'
  }/admin/auth/signin${redirectHost ? '?redirect=' + redirectHost : ''}`

  window.location.replace(domainRedirect)
}

export const useAuth = () => {
  const { addToast, removeAllToasts } = useToasts()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [
    {
      access: accessStore,
      accesses: accessesStore,
      merchant: merchantStore,
      merchants: merchantsStore,
      user: userStore,
    },
    dispatch,
  ] = useAuthContext()

  const {
    setAuth,
    setUser,
    setAccesses,
    setAccess,
    setMerchants,
    setMerchant,
    // setLogout,
  } = authActions(dispatch)

  const [loading, setLoading] = useState<boolean>(false)

  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

  const [gettingSession, setGettingSession] = useState<boolean>(true)

  const handleRefreshToken = async () => {
    return await refreshTokenRequest().then(
      ({
        data: {
          data: { access_token, expires_in },
        },
      }) => {
        setCookie('in_a', access_token)
        setCookie(
          'in_a_exp',
          `${getTime(add(new Date(), { seconds: expires_in - 300 }))}`
        )
      }
    )
  }

  const handleGetSession = async () => {
    setGettingSession(true)

    if (routesNoGetSession.includes(pathname)) {
      setGettingSession(false)
      return
    }

    try {
      const mid = getCookie('mid')

      const {
        data: {
          data: { accesses, user },
        },
      } = await getUserStatusRequest()

      handleRefreshToken()

      let access = accesses.find((access) => {
        const domain = ENV_PROD
          ? access.domain
          : `${access.domain.split('.')[0]}.${DOMAIN_COOKIE}`
        return domain === window.location.hostname
      })

      if (!access) {
        window.location.replace(
          `${ACCOUNT_URL || 'https://account.matic.io'}/admin`
        )
        return
      }

      access = access || accesses[0]

      const merchant =
        access.merchants.find((merchant) => merchant.id === atob(mid || '')) ||
        access.merchants[0]

      setCookie('cid', btoa(access.id), { noDomain: true })

      if (merchant) setCookie('mid', btoa(merchant.id), { noDomain: true })

      Sentry.setUser(
        sentryPrepareData({
          user,
          access: access || accesses[0],
          merchant,
        })
      )

      setAuth({
        user,
        accesses,
        access,
        merchants: access.merchants || [],
        merchant: merchant || null,
      })

      setGettingSession(false)

      const validPermission =
        user?.is_admin || access.is_owner || !routeExists(pathname)
          ? true
          : validPermissionsRoute(pathname, merchant.permissions)

      if (!validPermission) navigate('/unauthorized', { replace: true })
    } catch (error) {
      console.error(error)
      await handleLogout(window.location.host)
    }
  }

  const handleLogout = async (redirectHost = '') => {
    setLoading(true)
    try {
      controllerApi.abort()
      await signOutRequest()
      cleanTokenAndRedirect(redirectHost)
    } catch (error) {
      cleanTokenAndRedirect(redirectHost)
    }
  }

  const handleRefreshPermissions = async () => {
    try {
      if (merchantStore && !accessStore.is_owner) {
        const {
          data: { data },
        } = await refreshPermissionsRequest(merchantStore.member_id || '')

        setMerchant({ ...merchantStore, permissions: data.permissions })
      }
    } catch (error: any) {
      addToast('Error refreshing your permissions', {
        appearance: 'error',
        avoidToast: requestWasAborted(error?.message || ''),
      })
    }
  }

  const handleGetAccessesMerchants = async () => {
    try {
      const { data: requestData } = await getUserStatusRequest()
      const {
        data: { accesses },
      } = requestData
      const access = accesses.find((access) => access.id === accessStore.id)
      setAccesses(accesses)
      setAccess(access || accesses[0])
      setMerchants(access?.merchants || [])
      if (!merchantStore && access?.merchants.length) {
        setMerchant(access.merchants[0])
        setCookie('mid', btoa(access.merchants[0].id), { noDomain: true })
      }
    } catch (error: any) {
      messageErrorRequest(error).map((err: string) => {
        addToast(err, {
          appearance: 'error',
          avoidToast: requestWasAborted(error?.message || ''),
        })
      })
    }
  }

  const handleSelectAccessMerchant = (idMerchant: string) => {
    const merchant = merchantsStore.find(
      (merchant: TMerchant) => merchant.id === idMerchant
    )
    if (merchant) {
      setCookie('mid', btoa(merchant.id), { noDomain: true })
    }

    setMerchant(merchant || null)

    Sentry.setUser(
      sentryPrepareData({
        user: userStore,
        access: accessStore,
        merchant,
      })
    )
  }

  const handleChangeAccess = ({
    access,
    merchant,
  }: {
    access?: TAccess
    merchant?: TMerchantAccess
  }) => {
    if (access) setAccess(access)
    if (merchant) setMerchant(merchant)
  }

  const handleUpdateAccess = (access: TAccess) => {
    setAccess(access)
  }

  const handleUpdateMerchant = (merchant: TMerchantAccess) => {
    setMerchant(merchant)

    const replaceMerchant = (merchants: TMerchantAccess[]) =>
      merchants.map((prevMerchant) => {
        return merchant.id === prevMerchant.id ? merchant : prevMerchant
      })

    const replaceAccesses = (): TAccess[] =>
      accessesStore.map((acc: TAccess) => ({
        ...acc,
        merchants: replaceMerchant(acc.merchants),
      }))
    setAccesses(replaceAccesses())
  }

  const handleUpdateUser = async (values: TUser, { setSubmitting }: any) => {
    try {
      await profileUpdate(values)
      setUser({ ...values, photo: userStore?.photo })
      addToast('Profile updated successfully', {
        appearance: 'success',
      })
      setSubmitting(false)
    } catch (error: any) {
      setSubmitting(false)
      messageErrorRequest(error).map((err: string) => {
        addToast(err, {
          appearance: 'error',
          avoidToast: requestWasAborted(error?.message || ''),
        })
      })
    }
  }

  const handleUpdatePhoto = async (file: File) => {
    try {
      if (!file) return

      const checkSize = file.size < IMAGE_SIZE
      const checkType = IMAGE_FILE_FORMATS.includes(file.type)

      if (!checkSize) {
        addToast('File is very large (Max 1mb)', {
          appearance: 'error',
        })
        return
      }

      if (!checkType) {
        addToast('Only accept JPG, PNG or  GIF', {
          appearance: 'error',
        })
        return
      }

      addToast('Uploading photo...', {
        appearance: 'info',
        autoDismiss: false,
      })

      setLoadingUpload(true)

      if (userStore) setUser({ ...userStore, photo: '' })

      const {
        data: { data },
      } = await profileUpdatePhoto(file)

      if (userStore) setUser({ ...userStore, photo: data.photo_file_path })

      removeAllToasts()

      addToast('Profile updated successfully', {
        appearance: 'success',
      })
      setLoadingUpload(false)
    } catch (error: any) {
      removeAllToasts()
      setLoadingUpload(false)
      messageErrorRequest(error).map((err: string) => {
        addToast(err, {
          appearance: 'error',
          avoidToast: requestWasAborted(error?.message || ''),
        })
      })
    }
  }

  const handleRemovePhoto = async () => {
    try {
      setLoadingUpload(true)
      await profileDeletePhoto()
      if (userStore) setUser({ ...userStore, photo: null })
      addToast('Profile updated successfully', {
        appearance: 'success',
      })
      setLoadingUpload(false)
    } catch (error: any) {
      setLoadingUpload(false)
      messageErrorRequest(error).map((err: string) => {
        addToast(err, {
          appearance: 'error',
          avoidToast: requestWasAborted(error?.message || ''),
        })
      })
    }
  }

  return {
    auth: {
      user: userStore,
      access: accessStore,
      accesses: accessesStore,
      merchant: merchantStore,
      merchants: merchantsStore,
      gettingSession,
      handleChangeAccess,
      handleUpdateAccess,
      handleUpdateMerchant,
      handleGetSession,
      handleRefreshToken,
      handleRefreshPermissions,
    },
    merchantAccesses: {
      handleGetList: handleGetAccessesMerchants,
      handleSelect: handleSelectAccessMerchant,
    },
    account: {
      initialValues: userStore || initialValues,
      handleUpdateUser,
      handleUpdatePhoto,
      handleRemovePhoto,
      loadingUpload,
    },
    logout: {
      onLogout: handleLogout,
    },
    loading,
  }
}
