import { TAxiosResp } from '@matic.co/utils'
import { AxiosResponse } from 'axios'

// Api
import { api } from '@/utils/services/api'

// Type
import { TFastLoginSchema } from '@/hooks/useFastLogin'
import { TCredential, TGenerateSecretResponse } from '@/types/data/TCredential'
import { TMemberPermissions, TUserStatus } from '@/types/data/TProfile'
import { TSessionToken, TUserAdminData } from '@/types/data/TUser'
import { TUser } from '@/types/store/state'
import { TFormPasswordChange } from '@/types/form'

// Logout
export const signOutRequest = (): Promise<AxiosResponse> => {
  return api.get('/auth/admin/logout')
}

export const fastLogInRequest = (
  body: TFastLoginSchema
): Promise<TAxiosResp<TUserAdminData>> => {
  return api.post('/auth/admin/login', body)
}

export const getUserStatusRequest = (): Promise<TAxiosResp<TUserStatus>> => {
  return api.get('/auth/admin/user-status')
}

// Refresh token
export const refreshTokenRequest = (): Promise<TAxiosResp<TSessionToken>> => {
  return api.patch('/auth/admin/refresh-token')
}

// Refresh permissions
export const refreshPermissionsRequest = (
  member_id: string
): Promise<TAxiosResp<TMemberPermissions>> => {
  return api.get(`/auth/admin/permissions/?member_id=${member_id}`)
}

// Change Passowrd
export const changePasswordRequest = (
  body: Omit<TFormPasswordChange, 'new_password_confirm'>
): Promise<AxiosResponse> => {
  return api.patch('/auth/admin/change-password', body)
}

// Profile
export const profileRequest = (): Promise<TAxiosResp<TUser>> => {
  return api.get('/auth/profile/admin')
}

export const profileUpdate = ({
  first_name,
  last_name,
  cell_phone,
}: TUser): Promise<AxiosResponse> => {
  return api.put('/auth/profile/admin', {
    first_name,
    last_name,
    cell_phone,
  })
}

export const profileUpdatePhoto = (file: File): Promise<AxiosResponse> => {
  const formData = new FormData()

  formData.append('photo', file)
  return api.post('/auth/profile/admin/photo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const profileDeletePhoto = async (): Promise<AxiosResponse> => {
  return api.delete(`/auth/profile/admin/photo`)
}

// Credentails
export const credentialsListGet = (
  company_id: string
): Promise<TAxiosResp<TCredential>> => {
  return api.get(`/companies/${company_id}/credentials`)
}

export const credentialAddKey = async (
  company_id: string,
  app_name: string
): Promise<TAxiosResp<TCredential>> => {
  return api.post(`/auth/credentials`, {
    company_id,
    app_name,
  })
}

export const credentialDeleteKey = async (
  credential_id: string
): Promise<TAxiosResp<void>> => {
  return api.delete(`/auth/credentials/${credential_id}`)
}

export const credentialSecretGenerate = async (
  company_id: string
): Promise<TAxiosResp<TGenerateSecretResponse>> => {
  return api.get(`/companies/${company_id}/credentials/secret`)
}
