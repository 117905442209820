import { TConfig } from '@/types/store/state'
import { TDrawerItemsParams, TDrawerItems } from '@/types/TDrawer'

export const itemsDrawer = (
  { isAdmin = false, isOwner = false, permissions }: TDrawerItemsParams,
  alerts?: TConfig
): TDrawerItems[] => {
  const items: TDrawerItems[] = [
    {
      icon: 'dashboard',
      label: 'Dashboard',
      href: `/`,
      errors: 0,
      permissions: [''],
    },
    {
      icon: 'quick',
      label: 'Quick Payment',
      href: `/quick-payment`,
      errors: 0,
      permissions: [''],
    },
    {
      icon: 'merchants',
      label: 'Merchants',
      href: `/merchants`,
      errors: 0,
      alerts: alerts?.merchant_has_no_gateway
        ? 'You do have some merchant without any active gateway connected!'
        : alerts && !alerts.company_has_merchants
        ? 'You have not created any Merchant yet!'
        : '',
      permissions: ['merch-0'],
    },
    {
      icon: 'payment',
      label: 'Payments',
      href: `/payments`,
      errors: 0,
      permissions: ['pro-pay-0'],
    },
    {
      icon: 'user',
      label: 'User Management',
      href: `/users`,
      errors: 0,
      permissions: ['members-0'],
    },
    {
      icon: 'settings',
      label: 'Settings',
      href: `/company/settings`,
      errors: 0,
      permissions: ['comp-1'],
    },
  ]

  if (isAdmin) return items

  if (isOwner) return items

  return items.filter(
    (item) =>
      item.permissions.some((permission) => permissions.includes(permission)) ||
      item.href === '/'
  )
}
