import { MLoadingSuspense, MSplashLoader } from '@matic.co/core'
import { Suspense, lazy } from 'react'
import { Navigate, createBrowserRouter } from 'react-router-dom'

// Layouts
import { MLayoutMain } from '@/components/layouts/MLayoutMain'
import { MLayoutTabs } from '@/components/layouts/MLayoutTabs'
import { MMainLayout } from '@/components/layouts/MMainLayout'
import { RouterError } from '@/views/RouterError'

// Views
const DashboardView = lazy(() => import('@/views/Dashboard'))
const MerchantsListView = lazy(() => import('@/views/merchants/Lists'))
const MerchantDetailsView = lazy(() => import('@/views/merchants/Details'))
const MerchantGatewaysView = lazy(() => import('@/views/merchants/Gateways'))
const MerchantConfigView = lazy(() => import('@/views/merchants/Config'))
const MerchantWebhooksView = lazy(() => import('@/views/merchants/Webhooks'))
const PaymentsView = lazy(() => import('@/views/payments/Payments'))
const UsersListView = lazy(() => import('@/views/users/List'))
const QuickPaymentsView = lazy(() => import('@/views/QuickPayments'))
const PrintTransactionsView = lazy(() => import('@/views/PrintTransactions'))
const CompanySettingsView = lazy(() => import('@/views/company/Settings'))
const CompanyCredentialsView = lazy(() => import('@/views/company/Credentials'))
const AccountSettingsView = lazy(() => import('@/views/account/Settings'))
const AccountPasswordView = lazy(() => import('@/views/account/Password'))
const UnauthorizedView = lazy(() => import('@/views/Unauthorized'))
const Error404View = lazy(() => import('@/views/Error404'))

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MMainLayout />,
    errorElement: <RouterError />,
    children: [
      {
        element: <MLayoutMain />,
        children: [
          // Dashboard
          {
            index: true,
            element: (
              <Suspense fallback={<MLoadingSuspense />}>
                <DashboardView />
              </Suspense>
            ),
          },
          // Quick Payments
          {
            path: 'quick-payment',
            element: (
              <Suspense fallback={<MLoadingSuspense />}>
                <QuickPaymentsView />
              </Suspense>
            ),
          },
          // Merchants
          {
            path: 'merchants',
            element: (
              <Suspense fallback={<MLoadingSuspense />}>
                <MerchantsListView />
              </Suspense>
            ),
          },
          {
            path: 'merchants/:id',
            element: <MLayoutTabs module="merchants" />,
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <MerchantDetailsView />
                  </Suspense>
                ),
              },
              {
                path: 'gateways',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <MerchantGatewaysView />
                  </Suspense>
                ),
              },
              {
                path: 'config',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <MerchantConfigView />
                  </Suspense>
                ),
              },
              {
                path: 'webhooks',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <MerchantWebhooksView />
                  </Suspense>
                ),
              },
            ],
          },
          // Payments
          {
            path: 'payments',
            element: (
              <Suspense fallback={<MLoadingSuspense />}>
                <PaymentsView />
              </Suspense>
            ),
          },
          // Members
          {
            path: 'users',
            element: <MLayoutTabs module="users" />,
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <UsersListView />
                  </Suspense>
                ),
              },
            ],
          },
          // Company
          {
            path: 'company',
            element: <MLayoutTabs module="company" />,
            children: [
              {
                index: true,
                element: <Navigate to="/company/settings" />,
              },
              {
                path: 'settings',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <CompanySettingsView />
                  </Suspense>
                ),
              },
              {
                path: 'credentials',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <CompanyCredentialsView />
                  </Suspense>
                ),
              },
            ],
          },
          // Payments
          {
            path: 'account',
            element: <MLayoutTabs module="account" />,
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <AccountSettingsView />
                  </Suspense>
                ),
              },
              {
                path: 'password',
                element: (
                  <Suspense fallback={<MLoadingSuspense />}>
                    <AccountPasswordView />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      // Transactions
      {
        path: 'transactions/print/:id',
        element: (
          <Suspense fallback={<MLoadingSuspense />}>
            <PrintTransactionsView />
          </Suspense>
        ),
      },
      // Under Construction
      {
        path: '/under-construction',
        element: <h1>Under Construction</h1>,
      },
      // No permission
      {
        path: 'unauthorized',
        element: (
          <Suspense fallback={<MSplashLoader />}>
            <UnauthorizedView />
          </Suspense>
        ),
      },
      // 404
      {
        path: '*',
        element: (
          <Suspense fallback={<MSplashLoader />}>
            <Error404View />
          </Suspense>
        ),
      },
    ],
  },
])
