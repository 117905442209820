import { useEffect } from 'react'

// Components
import { MScreenLoader } from '@/components/common/MScreenLoader'

// Hooks
import { MFastLogin } from '@/components/providers/MAuthProvider/components/MFastLogin'
import { useAuth } from '@/hooks/modules/session/useAuth'
import { useUserActivity } from '@/hooks/modules/session/useUserActivity'
import useAxiosInterceptorResponse from '@/hooks/useAxiosInterceptorResponse'
import { styled } from '@matic.co/core'

const ContainerSplash = styled('div')({
  position: 'absolute',
  display: 'grid',
  placeContent: 'center',
  width: '100vw',
  height: '100vh',
})

export const MAuthProvider: React.FC = ({ children }) => {
  const {
    auth: { gettingSession, user, handleGetSession, handleRefreshPermissions },
  } = useAuth()
  const { mustOpenFastLogin, closeLoginDialog } = useAxiosInterceptorResponse()
  useUserActivity({ isOpenFastLogin: mustOpenFastLogin })

  useEffect(() => {
    handleGetSession()
  }, [])

  useEffect(() => {
    const intervalRefreshPermissions = setInterval(() => {
      handleRefreshPermissions()
    }, 300_000)
    return () => clearInterval(intervalRefreshPermissions)
  }, [])

  if (gettingSession) {
    return (
      <ContainerSplash>
        <MScreenLoader />
      </ContainerSplash>
    )
  }

  return (
    <>
      {children}
      <MFastLogin
        isOpen={mustOpenFastLogin}
        email={user?.email ?? ''}
        onClose={closeLoginDialog}
      />
    </>
  )
}
