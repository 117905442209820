import { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@matic.co/core'

// Styles
const LinkStyled = styled(Link)<any>(({ theme }) => ({
  textDecoration: 'none',
  '&.colorText': {
    color: theme.palette.text.primary,
  },
}))

export type ComponentProps = {
  /**
   * The path or URL to navigate to
   */
  to: string | Record<string, unknown>
  /**
   * Optional decorator for the path that will be shown in the browser URL bar
   */
  as?: string
  /**
   * Prefetch the page in the background. Any <Link /> that is in the viewport (initially or through scroll) will be preloaded. Prefetch can be disabled by passing prefetch={false}
   */
  prefetch?: true | false
  /**
   * Replace the current history state instead of adding a new url into the stack
   */
  replace?: true | false
  /**
   * Forces Link to send the href property to its child
   */
  passHref?: true | false
  /**
   * Css class
   */
  className?: string
  /**
   * The color of the component
   */
  color?: 'primary' | 'info' | 'success' | 'warning' | 'error' | 'text'
}

export const MLink: React.FC<ComponentProps> = forwardRef(
  ({ children, className, color = 'primary', ...other }, ref) => (
    <LinkStyled
      ref={ref}
      sx={{
        color: `${color}.main`,
      }}
      className={`${color === 'text' ? 'colorText' : ''} ${className}`}
      {...other}
    >
      {children}
    </LinkStyled>
  )
)
