import { useEffect, useState } from 'react'
import { ACCOUNT_URL } from '@/config/env'
import { removeCookie, setCookie } from '@/utils/cookies'
import { api, controllerApi } from '@/utils/services'

const useAxiosInterceptorResponse = () => {
  const [mustOpenFastLogin, setMustOpenFastLogin] = useState(false)

  const handleCancelFastLogin = () => {
    setMustOpenFastLogin(false)
    removeCookie('in_a')
    removeCookie('cid', { noDomain: true })
    const domainRedirect = `${
      ACCOUNT_URL || 'https://account.matic.io'
    }/admin/auth/signin?redirect=${window.location.host}`
    window.location.replace(domainRedirect)
  }

  const enableResponseInterceptors = () =>
    api.interceptors.response.use(
      function (response) {
        if (response.headers['access-token']) {
          setCookie('in_a', response.headers['access-token'])
        }
        return response
      },
      function (error: any) {
        const isTokenExpired = error?.response?.status === 401
        const isLogoutEndpoint =
          error?.response?.config?.url === '/auth/admin/logout'

        if (isTokenExpired && !isLogoutEndpoint) {
          controllerApi.abort()
          setMustOpenFastLogin(true)
        }
        return Promise.reject(error)
      }
    )

  useEffect(() => {
    enableResponseInterceptors()
  }, [])

  return {
    closeLoginDialog: handleCancelFastLogin,
    mustOpenFastLogin,
  }
}

export default useAxiosInterceptorResponse
