import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router-dom'

import {
  APP_ENVIRONMENT,
  APP_RELEASE,
  SENTRY_DSN,
  SENTRY_ENABLED,
} from '@/config/env'
import { router } from '@/router'
import { isFailedImported, sentryGetExceptionValue } from '@matic.co/utils'

Sentry.init({
  enabled: SENTRY_ENABLED,
  environment: APP_ENVIRONMENT,
  dsn: SENTRY_DSN,
  release: APP_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (
      event.exception &&
      !isFailedImported(sentryGetExceptionValue(event.exception))
    ) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        lang: 'en',
      })
    }
    return event
  },
})

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)
