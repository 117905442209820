// Components
import { MError, ThemeWrapper } from '@matic.co/core'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'

export const RouterError = () => {
  const error: any = useRouteError()

  useEffect(() => {
    NProgress.done()
  }, [])

  return (
    <ThemeWrapper>
      <MError message={error?.message} />
    </ThemeWrapper>
  )
}
