// Context
import { TAction } from '@/types/TContext'

// Constants
import { constants } from '@/context/constants'

// Types
import { TAccess, TAuth, TMerchantAccess, TUser } from '@/types/store/state'

export const initialAuthState: TAuth = {
  user: null,
  validated: false,
  accesses: [],
  access: {
    id: '',
    name: '',
    domain: '',
    alias: '',
    is_owner: false,
    merchants: [],
    logo: null,
  },
  merchants: [],
  merchant: null,
}

export const authReducer = (state: TAuth, action: TAction<any>): TAuth => {
  switch (action.type) {
    case constants.SET_AUTH: {
      const data: TUser = action.payload
      return {
        ...state,
        validated: true,
        ...data,
      }
    }
    case constants.SET_USER: {
      const data: TUser = action.payload
      return {
        ...state,
        user: data,
      }
    }

    case constants.SET_ACCESSES: {
      const data: TAccess[] = action.payload
      return {
        ...state,
        accesses: data,
      }
    }

    case constants.SET_ACCESS: {
      const data: TAccess = action.payload
      return {
        ...state,
        access: data,
        accesses: state.accesses.map((item) =>
          item.id !== data.id
            ? item
            : {
                ...item,
                alias: data.alias,
                logo: data.logo,
                name: data.name,
              }
        ),
      }
    }

    case constants.SET_MERCHANTS: {
      const data: TMerchantAccess[] = action.payload
      return {
        ...state,
        merchants: data,
      }
    }

    case constants.SET_MERCHANT: {
      const data: TMerchantAccess = action.payload
      return {
        ...state,
        merchant: data,
      }
    }

    case constants.LOGOUT: {
      return {
        ...initialAuthState,
      }
    }

    default:
      return state
  }
}
