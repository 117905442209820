import { Dispatch } from 'react'

// Types
import { TAction } from '@/types/TContext'

// Constants
import { constants } from '@/context/constants'

export const mainActions = (dispatch: Dispatch<TAction>) => ({
  setOpenDrawer: (openDrawer: boolean) =>
    dispatch({
      type: constants.SET_OPEN_DRAWER,
      payload: { openDrawer },
    }),
})
