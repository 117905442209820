// Context
import { configActions } from '@/context/actions/configActions'
import { useConfigContext } from '@/context/configContext'

// Services
import { initLoadRequest } from '@/utils/services'

export const useConfig = () => {
  const [, dispatch] = useConfigContext()

  const loadInitialConfig = async () => {
    const {
      data: { data },
    } = await initLoadRequest()
    configActions(dispatch).setConfig(data)
  }

  return {
    loadInitialConfig,
  }
}
