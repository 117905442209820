import { styled, MSpinner } from '@matic.co/core'

const ContainerStyled = styled('div')({
  height: '70vh',
  display: 'grid',
  placeContent: 'center',
})

export type ComponentProps = {
  size?: number
  className?: string
  color?:
    | 'white'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
}

export const MScreenLoader = ({
  className,
  color = 'white',
  size = 100,
}: ComponentProps) => (
  <ContainerStyled className={className}>
    <MSpinner color={color} size={size} />
  </ContainerStyled>
)
