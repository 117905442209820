import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { http } from '@/utils/http'

export const useSelectCompany = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const toggleIsOpen = () => setIsOpen(!isOpen)

  const handleGoToTenant = (domain: string) => {
    if (domain === '/') navigate('/')
    else location.replace(`${http}://${domain}`)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value)

  return {
    isOpen,
    searchValue,
    handleSearch,
    handleGoToTenant,
    toggleIsOpen,
  }
}
