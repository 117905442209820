import { MDivider } from '@matic.co/core'
import { TTab } from '@matic.co/utils'
import { Outlet, useParams } from 'react-router-dom'

// Components
import { MTabsContent } from '@/components/containers/MTabsContent'

export type ComponentProps = {
  module?: 'users' | 'company' | 'merchants' | 'account'
  children?: React.ReactNode
}

export const MLayoutTabs = ({ module, children }: ComponentProps) => {
  const { id } = useParams()

  const listTabs: Record<string, TTab[]> = {
    users: [
      {
        label: 'User Management',
        to: '/users',
      },
    ],
    company: [
      {
        label: 'General Information',
        to: `/company/settings`,
      },
      {
        label: 'API Keys',
        to: `/company/credentials`,
      },
    ],
    merchants: [
      {
        label: 'General Information',
        to: `/merchants/${id}`,
      },
      {
        label: 'Gateway Integration',
        to: `/merchants/${id}/gateways`,
      },
      {
        label: 'Configuration',
        to: `/merchants/${id}/config`,
      },
      {
        label: 'Webhooks',
        to: `/merchants/${id}/webhooks`,
      },
    ],
    account: [
      {
        label: 'Account Settings',
        to: `/account`,
      },
      {
        label: 'Password',
        to: `/account/password`,
      },
    ],
  }

  return (
    <MTabsContent tabs={listTabs[module as string]}>
      <MDivider sx={{ marginBottom: 4, marginTop: -2 }} />

      {children || <Outlet />}
    </MTabsContent>
  )
}
