import { MToastProvider, ThemeWrapper } from '@matic.co/core'
import { Outlet } from 'react-router-dom'

import { MAppProvider } from '@/components/providers/MAppProvider'
import { MAuthProvider } from '@/components/providers/MAuthProvider'
import { AppProvider } from '@/context/app'

export const MMainLayout = () => {
  return (
    <ThemeWrapper>
      <AppProvider>
        <MToastProvider>
          <MAuthProvider>
            <MAppProvider>
              <Outlet />
            </MAppProvider>
          </MAuthProvider>
        </MToastProvider>
      </AppProvider>
    </ThemeWrapper>
  )
}
