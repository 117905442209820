import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { TAxiosHeaders, TAxiosHeadersReturn } from '@matic.co/utils'

// Config
import { API_URL } from '@/config/env'

// Utils
import { getAllCookie } from '@/utils/cookies'

export const setHeaders: ({ Cookie, jwt, cid }: TAxiosHeaders) => {
  headers: TAxiosHeadersReturn
} = ({ Cookie, jwt, cid }: TAxiosHeaders) => {
  const headers: TAxiosHeadersReturn = {}

  if (Cookie) headers['Cookie'] = Cookie
  if (jwt) headers['Authorization'] = `Bearer ${jwt}`
  if (cid) headers['CID'] = cid

  return { headers }
}

export const apiCreate: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json', crossDomain: 'true' },
})

export const controllerApi = new AbortController()

apiCreate.interceptors.request.use(
  function (config: any) {
    const { cid, mid, in_a } = getAllCookie()

    if (in_a) config.headers.common.Authorization = `Bearer ${in_a}`

    if (cid) config.headers.common.cid = atob(cid)

    if (mid) config.headers.common.mid = atob(mid)

    if (!controllerApi.signal.aborted) config.signal = controllerApi.signal

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const api = apiCreate

// Banks
export const banksGetAllRequest = (): Promise<AxiosResponse> => {
  return api.get('/portal/banks')
}

// States
export const statesGetAllRequest = (): Promise<AxiosResponse> => {
  return api.get('/public/states')
}
