import { useMainContext } from '@/context/mainContext'
import { mainActions } from '@/context/actions/mainActions'

export const useMain = () => {
  const [{ openDrawer }, dispatch] = useMainContext()
  const { setOpenDrawer: setOpenDrawerAction } = mainActions(dispatch)

  const setOpenDrawer = (open?: boolean) =>
    setOpenDrawerAction(open ?? !openDrawer)

  return { openDrawer, setOpenDrawer }
}
