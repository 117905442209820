import { styled, useMediaQuery } from '@matic.co/core'
import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { MHead } from '@/components/common/MHead'
import { MDrawer } from '@/components/common/drawer/MDrawer'
import { MNavbar } from '@/components/common/navbar/MNavbar'
import { useConfigContext } from '@/context/configContext'
import { useAuth } from '@/hooks/modules/session/useAuth'
import { TDrawerItems } from '@/types/TDrawer'
import { itemsDrawer } from '@/utils/itemsDrawer'
import { useMain } from '@/hooks/useMain'

// Styles
const ContainerStyled = styled('div')({
  display: 'flex',
})
const MainStyled = styled('main')({
  flexGrow: 1,
})
const ContentStyled = styled('div')({
  padding: 20,
  paddingBottom: 50,
  display: 'grid',
})

export type ComponentProps = {
  /**
   * Title that will be displayed in the <title /> tag in the <head> of the application
   */
  title?: string
  /**
   * with animation
   *
   * @default true
   */
  withAnimation?: boolean
}

export const MLayoutMain: React.FC<ComponentProps> = ({
  title,
  withAnimation = true,
}) => {
  const {
    auth: { user, access, accesses, merchant },
    logout: { onLogout },
    loading: loadingLogout,
  } = useAuth()
  const [isMobile, setIsMobile] = useState(false)
  const { openDrawer, setOpenDrawer } = useMain()
  const [config] = useConfigContext()

  const maxWidth767 = useMediaQuery('(max-width:767px)')

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer)

  useEffect(() => {
    setOpenDrawer(!maxWidth767)
    setIsMobile(maxWidth767)
  }, [maxWidth767])

  const itemsMenu: TDrawerItems[] = useMemo(
    () =>
      itemsDrawer(
        {
          isAdmin: user?.is_admin,
          isOwner: access.is_owner,
          permissions: merchant?.permissions || [],
        },
        config
      ),
    [access, user, merchant, config]
  )

  return (
    <>
      <MHead title={title} />

      <ContainerStyled>
        <MDrawer
          itemsMenu={itemsMenu}
          open={openDrawer}
          onClose={handleDrawerToggle}
          isMobile={isMobile}
          access={access}
          accesses={accesses}
        />

        <MainStyled>
          <MNavbar
            user={user}
            access={access}
            accesses={accesses}
            openDrawer={!isMobile && openDrawer}
            menuOnClick={handleDrawerToggle}
            onLogout={onLogout}
            loadingLogout={loadingLogout}
          />

          <ContentStyled
            className={`${
              withAnimation && 'animate__animated animate__fadeIn'
            }`}
          >
            <Outlet />
          </ContentStyled>
        </MainStyled>
      </ContainerStyled>
    </>
  )
}
