import { Dispatch } from 'react'

// Types
import { TAction } from '@/types/TContext'
import { TConfig } from '@/types/store/state'

// Constants
import { constants } from '@/context/constants'

export const configActions = (dispatch: Dispatch<TAction>) => ({
  setConfig: (data: TConfig) =>
    dispatch({
      type: constants.SET_CONFIG,
      payload: data,
    }),
  setHaveCredentials: (data: boolean) =>
    dispatch({
      type: constants.SET_HAVE_CREDENTIALS,
      payload: data,
    }),
  setMerchantsNotAssigned: (data: boolean) =>
    dispatch({
      type: constants.SET_MERCHANTS_NOT_ASSIGNED,
      payload: data,
    }),

  setResetConfig: () =>
    dispatch({
      type: constants.RESET_CONFIG,
    }),
})
