import { Dispatch } from 'react'

// Types
import { TAction } from '@/types/TContext'
import { TAccess, TAuth, TMerchantAccess, TUser } from '@/types/store/state'

// Constants
import { constants } from '@/context/constants'

export const authActions = (dispatch: Dispatch<TAction>) => ({
  setAuth: (data: TAuth) =>
    dispatch({
      type: constants.SET_AUTH,
      payload: data,
    }),
  setUser: (data: TUser | null) =>
    dispatch({
      type: constants.SET_USER,
      payload: data,
    }),
  setAccesses: (data: TAccess[]) =>
    dispatch({
      type: constants.SET_ACCESSES,
      payload: data,
    }),
  setAccess: (data: TAccess) =>
    dispatch({
      type: constants.SET_ACCESS,
      payload: data,
    }),
  setMerchants: (data: TMerchantAccess[]) =>
    dispatch({
      type: constants.SET_MERCHANTS,
      payload: data,
    }),
  setMerchant: (data: TMerchantAccess | null) =>
    dispatch({
      type: constants.SET_MERCHANT,
      payload: data,
    }),
  setLogout: () =>
    dispatch({
      type: constants.LOGOUT,
    }),
})
