import { TAxiosResp } from '@matic.co/utils'

// Api
import { api } from '@/utils/services'

// Types
import { TConfig } from '@/types/store/state'

export const initLoadRequest = (): Promise<TAxiosResp<TConfig>> => {
  return api.get(`/auth/admin/load-app`)
}
