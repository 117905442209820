import { Fragment } from 'react'
import {
  styled,
  MButton,
  MClickAwayListener,
  MFade,
  MSpinner,
  MTooltip,
  MGrid,
  MTextField,
} from '@matic.co/core'
import {
  EvaArrowIosDownwardOutline,
  EvaArrowIosUpwardOutline,
  EvaCheckmark,
  EvaSearchOutline,
} from '@matic.co/icons'

// Components
import { MAvatar } from '@/components/common/MAvatar'

// Hooks
import { TMerchantAccess } from '@/types/store/state'
import { useSelectMerchant } from '@/hooks/useSelectMerchant'

// Styles
const ContainerStyled = styled('div')({
  margin: 0,
})
const LabelStyled = styled('label')({
  marginBottom: 6,
  marginLeft: 5,
})
const MButtonStyled = styled(MButton)(({ theme }) => ({
  '&.MuiButton-root': {
    height: 37,
    width: 230,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.grayBluePrimary,
    borderColor: theme.palette.grey[200],

    '& svg': {
      color: theme.palette.grey[300],
    },
  },
}))
const BtnSpinnerContainerStlyed = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})
const BtnMerchantContainerStlyed = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: 'calc(100% - 15px)',
})
const BtnIconsContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const BtnTextStyled = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textTransform: 'none',
})
const SelectAllTextStyled = styled('span')({
  fontWeight: 700,
})
const MAvatarStyled = styled(MAvatar)({
  marginRight: 8,
})
const ItemsContainerStyled = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  marginTop: 5,
  borderRadius: 8,
  position: 'absolute',
  width: 230,
  background: '#fff',
  zIndex: 100,
  boxShadow: theme.shadows[24],
}))
const ItemsUlStyled = styled('ul')(({ theme }) => ({
  margin: 0,
  listStyle: 'none',
  padding: 0,
  maxHeight: 300,
  overflowX: 'hidden',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: 7,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    borderRadius: 30,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 30,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}))
const SearchBar = styled(MTextField)({
  marginBottom: 4,
  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    fontSize: 12,
  },
})

const ItemLiStyled = styled('li')(({ theme }) => ({
  height: 42,
  padding: '0px 15px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '&.active': {
    fontWeight: 800,
    backgroundColor: '#f3f6fd',
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[100],
  },
  '& svg': {
    marginLeft: 'auto',
    minWidth: 18,
    maxWidth: 18,
    color: theme.palette.primary.main,
  },
}))
const IconSpaceStyled = styled(MGrid)({
  width: 38,
  height: 30,
})

export type ComponentProps = {
  /**
   * Title merchant list
   */
  title?: string
  /**
   * Whether the filter accept multiple selection
   */
  multiple?: boolean
  /**
   * List of merchants
   */
  merchants: TMerchantAccess[]
  /**
   * merchant selected
   */
  merchantActive?: TMerchantAccess | TMerchantAccess[]
  /**
   * handle click on merchant
   */
  handleSelect: (merchant: TMerchantAccess) => void
  /**
   * handle click on select all
   */
  handleSelectAll?: () => void
  /**
   * Open merchant list
   */
  open?: boolean
  /**
   * handle open merchant list
   */
  handleOpen: () => void
  /**
   * handle close merchant list
   */
  handleClose: () => void
  /**
   * With logo
   * @default true
   */
  withLogo?: boolean
  /**
   * Loading merchants
   */
  loading?: boolean
  /**
   * Disable the button
   */
  disabled?: boolean
  /**
   * className
   */
  className?: string
  /**
   * className container
   */
  classNameContainer?: string
  /**
   * className items container
   */
  classNameItems?: string
}

export const MMerchantsList = ({
  title = '',
  className = '',
  classNameItems = '',
  classNameContainer = '',
  merchants,
  merchantActive = [],
  multiple,
  open,
  loading,
  disabled,
  withLogo = true,
  handleSelect,
  handleSelectAll,
  handleClose,
  handleOpen,
}: ComponentProps) => {
  const {
    allSelected,
    searchValue,
    merchantsList,
    merchantActives,
    isSelected,
    handleSearch,
  } = useSelectMerchant({
    merchantActive,
    merchants,
    isSelectOpened: open,
  })

  return (
    <ContainerStyled className={classNameContainer}>
      <MClickAwayListener onClickAway={handleClose}>
        <div>
          {title && <LabelStyled>{title}</LabelStyled>}
          <MButtonStyled
            variant="outlined"
            className={className}
            fullWidth
            disabled={loading || disabled}
            onClick={open ? handleClose : handleOpen}
          >
            {loading ? (
              <BtnSpinnerContainerStlyed className="animate__animated animate__fadeIn">
                <MSpinner size={20} />
              </BtnSpinnerContainerStlyed>
            ) : (
              <BtnMerchantContainerStlyed className="animate__animated animate__fadeIn">
                {merchantsList.length === 0 ? (
                  <BtnTextStyled>There are no merchants</BtnTextStyled>
                ) : (
                  <>
                    {merchantActives.length === 1 && (
                      <>
                        {withLogo && (
                          <MAvatarStyled
                            src={
                              merchantActives[0]?.logo ||
                              '/images/MerchantDefault.svg'
                            }
                            size={30}
                          />
                        )}
                        <BtnTextStyled>
                          {merchantActives[0]?.name}
                        </BtnTextStyled>
                      </>
                    )}
                    {merchantActives.length === 0 && (
                      <BtnTextStyled>Not merchant selected</BtnTextStyled>
                    )}
                    {merchantActives.length > 1 && (
                      <BtnTextStyled>
                        {merchantActives.map((merchantActive, index) => (
                          <Fragment key={index}>
                            {merchantActive?.name}
                            {merchantActives.length - 1 > index && ', '}
                          </Fragment>
                        ))}
                      </BtnTextStyled>
                    )}
                  </>
                )}
              </BtnMerchantContainerStlyed>
            )}
            <BtnIconsContainerStyled>
              <EvaArrowIosUpwardOutline size={15} />
              <EvaArrowIosDownwardOutline size={15} style={{ marginTop: -6 }} />
            </BtnIconsContainerStyled>
          </MButtonStyled>
          <MFade in={open}>
            <ItemsContainerStyled className={classNameItems}>
              {merchants.length > 7 && (
                <SearchBar
                  fullWidth
                  type="text"
                  size="small"
                  placeholder="Search merchant"
                  value={searchValue}
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: <EvaSearchOutline size={18} />,
                  }}
                />
              )}
              <ItemsUlStyled>
                {multiple && merchantsList.length > 1 && (
                  <ItemLiStyled
                    className={`${allSelected ? 'active' : ''}`}
                    onClick={handleSelectAll}
                  >
                    {withLogo && <IconSpaceStyled></IconSpaceStyled>}
                    <SelectAllTextStyled>Select All</SelectAllTextStyled>
                    {allSelected && <EvaCheckmark />}
                  </ItemLiStyled>
                )}
                {merchantsList.map((merchant) => (
                  <MTooltip
                    title={merchant.name}
                    key={merchant.id}
                    placement="right"
                  >
                    <ItemLiStyled
                      className={`${isSelected(merchant.id) ? 'active' : ''}`}
                      onClick={() => handleSelect(merchant)}
                    >
                      {withLogo && (
                        <MAvatarStyled
                          src={merchant?.logo || '/images/MerchantDefault.svg'}
                          size={30}
                        />
                      )}
                      <BtnTextStyled>{merchant.name}</BtnTextStyled>
                      {isSelected(merchant.id) && <EvaCheckmark />}
                    </ItemLiStyled>
                  </MTooltip>
                ))}
              </ItemsUlStyled>
            </ItemsContainerStyled>
          </MFade>
        </div>
      </MClickAwayListener>
    </ContainerStyled>
  )
}
