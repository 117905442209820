import { useToasts } from '@matic.co/core'
import { Yup, messageErrorRequest, requestWasAborted } from '@matic.co/utils'
import { add, getTime } from 'date-fns'
import { useFormik } from 'formik'

import { setCookie } from '@/utils/cookies'
import { http } from '@/utils/http'
import { fastLogInRequest } from '@/utils/services'

export type TFastLoginSchema = {
  email: string
  password: string
}

const getInitialValues = (email: string): TFastLoginSchema => ({
  email,
  password: '',
})

const validationSchema: Yup.SchemaOf<TFastLoginSchema> = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email required'),
  password: Yup.string().required('Password required'),
})

const currentAccess = window.location.hostname

const useFastLogin = (email: string) => {
  const { addToast } = useToasts()

  const handleFastLogin = async (values: TFastLoginSchema) => {
    try {
      const {
        data: {
          data: { access_token, expires_in, accesses: availableAccesses },
        },
      } = await fastLogInRequest(values)

      const currentAccessStillAvailable = availableAccesses.some(
        ({ domain }) => domain === currentAccess
      )

      if (currentAccessStillAvailable || availableAccesses.length > 0) {
        setCookie('in_a', access_token)
        setCookie(
          'in_a_exp',
          `${getTime(add(new Date(), { seconds: expires_in - 300 }))}`
        )

        currentAccessStillAvailable
          ? window.location.reload()
          : window.location.replace(`${http}://${availableAccesses[0].domain}`)
      }
    } catch (error: any) {
      messageErrorRequest(error).map((err: string) => {
        addToast(err, {
          appearance: 'error',
          avoidToast: requestWasAborted(error?.message || ''),
        })
      })
    }
  }

  const formikMethods = useFormik({
    onSubmit: handleFastLogin,
    initialValues: getInitialValues(email),
    validationSchema,
  })

  return { ...formikMethods }
}

export default useFastLogin
