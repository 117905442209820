// Context
import { TAction } from '@/types/TContext'

// Constants
import { constants } from '@/context/constants'

// Types
import { TMain } from '@/types/store/state'

export const initialConfigState: TMain = {
  openDrawer: true,
}

export const mainReducer = (
  state: TMain,
  { type, payload }: TAction<TMain>
): TMain => {
  switch (type) {
    case constants.SET_OPEN_DRAWER: {
      return {
        ...state,
        openDrawer: Boolean(payload?.openDrawer),
      }
    }

    default:
      return state
  }
}
