import {
  styled,
  MDivider,
  MPopover,
  MTypography,
  MSpinner,
  MList,
  MListItem,
  MListItemText,
  MConfirmButton,
  MTooltip,
  PopoverProps,
} from '@matic.co/core'

// Components
import { MLink } from '@/components/common/MLink'

// Types
import { TAccess, TUser } from '@/types/store/state'
import { MSelectCompany } from '../MSelectCompany'

// Styles
const UserContentStyled = styled('div')({
  marginBottom: 15,
})
const UserNameStyled = styled(MTypography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.grayBluePrimary,
  padding: '0 10px',
  minHeight: 24,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))
const UserEmailStyled = styled(MTypography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.grayBlueSecundary,
  padding: '0 10px',
  minHeight: 21,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))
const ListItemStyled = styled(MListItem)({
  padding: '8px 10px',
})
const TenantsLabelStyled = styled(MTypography)(({ theme }) => ({
  marginTop: 12,
  marginLeft: 10,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.text.grayBlueSecundary,
}))
const TenantsDividerStyled = styled(MDivider)({
  marginTop: 10,
})
const LogoutListItemStyled = styled(MListItem)({
  padding: 0,
})
const ConfirmButtonStyled = styled(MConfirmButton)({
  textAlign: 'left',
  padding: '8px 10px',
})

export type ComponentProps = {
  /**
   * Callback fired when the component requests to be closed
   */
  onClose?: PopoverProps['onClose']
  /**
   * A HTML element, or a function that returns it. It's used to set the position of the menu
   */
  anchorEl?: PopoverProps['anchorEl']
  /**
   * User with the session started
   */
  user: TUser | null
  /**
   * Access with the session started
   */
  access: TAccess
  /**
   * List of accesses available for this session
   */
  accesses: TAccess[]
  /**
   * Callback fired when log out
   */
  onLogout: () => void
  /**
   * Shows if the logout is loading
   */
  loadingLogout?: boolean
}

export const MNavbarMenu = ({
  anchorEl,
  user,
  access,
  accesses,
  loadingLogout,
  onLogout,
  onClose = () => undefined,
}: ComponentProps) => {
  const isMenuOpen = Boolean(anchorEl)

  const userName =
    (user?.first_name
      ? `${user?.first_name} ${user?.last_name}`
      : user?.email) || ''

  return (
    <MPopover
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        style: {
          marginTop: 5,
          width: 220,
          padding: 10,
        },
      }}
    >
      <UserContentStyled>
        <MTooltip title={userName}>
          <UserNameStyled>{userName}</UserNameStyled>
        </MTooltip>
        {user?.first_name && (
          <MTooltip title={user.email}>
            <UserEmailStyled variant="caption">{user.email}</UserEmailStyled>
          </MTooltip>
        )}
      </UserContentStyled>
      <MDivider orientation="horizontal" variant="fullWidth" />
      <TenantsLabelStyled>Logged in as...</TenantsLabelStyled>

      <MList>
        <MSelectCompany
          accesses={accesses}
          initialAccess={access}
          disabled={loadingLogout}
        />

        <TenantsDividerStyled orientation="horizontal" variant="fullWidth" />

        <ListItemStyled
          button={true}
          component={MLink}
          to={`/account`}
          onClick={() => onClose({}, 'backdropClick')}
          disabled={loadingLogout}
        >
          <MListItemText>Account Settings</MListItemText>
        </ListItemStyled>
        <LogoutListItemStyled disabled={loadingLogout}>
          <ConfirmButtonStyled
            onSuccess={() => onLogout()}
            fullWidth
            cancelProps={{
              color: 'white',
            }}
            variant="text"
            loading={loadingLogout}
            title="Do you want to log out?"
          >
            <MListItemText>
              {loadingLogout ? (
                <MSpinner size={25} color="primary" />
              ) : (
                'Logout'
              )}
            </MListItemText>
          </ConfirmButtonStyled>
        </LogoutListItemStyled>
      </MList>
    </MPopover>
  )
}
