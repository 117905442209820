import useFastLogin from '@/hooks/useFastLogin'
import {
  MButton,
  MDialog,
  MDialogActions,
  MDialogContent,
  MInputPassword,
  MTextField,
  MTypography,
  styled,
} from '@matic.co/core'
import { handleError } from '@matic.co/utils'

const TitleStyled = styled(MTypography)({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '32px',
})
const FormStyled = styled('form')({
  marginTop: 24,
})

export type ComponentProps = {
  isOpen: boolean
  email: string
  onClose: VoidFunction
}

export const MFastLogin = ({ isOpen, email, onClose }: ComponentProps) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFastLogin(email)

  return (
    <MDialog
      open={isOpen}
      hideCloseButton
      maxWidth="xs"
      aria-labelledby="login-dialog-title"
      aria-describedby="login-dialog-description"
    >
      <MDialogContent
        id="login-dialog-description"
        sx={{
          padding: '40px 40px 24px 40px',
        }}
      >
        <TitleStyled>Your session is already expired!</TitleStyled>
        <MTypography sx={{ fontSize: 14 }} color="textSecondary">
          If you want to keep your session alive, please login.
        </MTypography>
        <FormStyled onSubmit={handleSubmit}>
          <MTextField
            fullWidth
            disabled
            label="Email"
            type="email"
            name="email"
            value={values.email}
          />
          <MInputPassword
            fullWidth
            label="Password"
            name="password"
            value={values.password}
            disabled={isSubmitting}
            error={handleError(errors.password, touched.password).show}
            helperText={handleError(errors.password, touched.password).text}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <MDialogActions
            sx={{
              justifyContent: 'flex-end',
              paddingRight: 0,
              paddingBottom: 0,
            }}
          >
            <MButton
              type="button"
              color="white"
              variant="outlined"
              onClick={onClose}
            >
              Exit
            </MButton>
            <MButton
              loading={isSubmitting}
              type="submit"
              color="primary"
              variant="contained"
            >
              Log in
            </MButton>
          </MDialogActions>
        </FormStyled>
      </MDialogContent>
    </MDialog>
  )
}
