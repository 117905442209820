export const constants = {
  // Main
  SET_OPEN_DRAWER: 'SET_OPEN_DRAWER',

  // Customers
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  SET_CUSTOMERS_LOADING: 'SET_CUSTOMERS_LOADING',
  SET_CUSTOMERS_DETAILS_LOADING: 'SET_CUSTOMERS_DETAILS_LOADING',
  RESET_CUSTOMERS_STATE: 'RESET_CUSTOMERS_STATE',

  // Auth
  SET_AUTH: 'SET_AUTH',
  SET_USER: 'SET_USER',
  SET_ACCESSES: 'SET_ACCESSES',
  SET_ACCESS: 'SET_ACCESS',
  SET_MERCHANTS: 'SET_MERCHANTS',
  SET_MERCHANT: 'SET_MERCHANT',
  LOGOUT: 'LOGOUT',

  // Config
  SET_CONFIG: 'SET_CONFIG',
  SET_HAVE_CREDENTIALS: 'SET_HAVE_CREDENTIALS',
  SET_MERCHANTS_NOT_ASSIGNED: 'SET_MERCHANTS_NOT_ASSIGNED',
  RESET_CONFIG: 'RESET_CONFIG',
}
