export const getMainPath: (
  pathItem: string,
  current: string,
  alias?: string
) => boolean = (itemPath = '', currentPath = '') => {
  if (itemPath === currentPath && itemPath === '/') {
    return true
  } else {
    const itemPathArray: string[] = itemPath.split('/').filter((p: string) => p)
    const currentPathArray: string[] = currentPath
      .split('/')
      .filter((p: string) => p)

    let itemPathFirst: string = itemPathArray[0]
    let currentPathFirst = currentPathArray.length ? currentPathArray[0] : ''

    if (itemPathFirst === 'merchant' && currentPathFirst === 'merchant') {
      if (
        itemPathArray[0] === itemPathArray[0] &&
        itemPathArray.length === 1 &&
        currentPathArray.length === 1
      ) {
        itemPathFirst = '/merchant'
        currentPathFirst = '/merchant'
      } else {
        itemPathFirst = `/merchant/${
          itemPathArray[itemPathArray[1] === 'payments' ? 2 : 1]
        }`
        currentPathFirst = `/merchant/${
          currentPathArray[currentPathArray[1] === 'payments' ? 2 : 1]
        }`
      }
    }

    return itemPathFirst === currentPathFirst
  }
}
