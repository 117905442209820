import { Cookies, TCookiesOptions } from '@matic.co/utils'

// Config
import { APP_ENVIRONMENT, DOMAIN_COOKIE } from '@/config/env'

const cookies = new Cookies(DOMAIN_COOKIE, APP_ENVIRONMENT)

export const setCookie = (
  name: string,
  value: string,
  options?: TCookiesOptions
) => {
  cookies.setCookie(name, value, options)
}

export const getCookie = (name: string) => cookies.getCookie(name)

export const getAllCookie = () => cookies.getAllCookie()

export const removeCookie = (name: string, options?: TCookiesOptions) => {
  cookies.removeCookie(name, options)
}
