import {
  MDialog,
  MDialogContent,
  MGrid,
  MSpinner,
  MTypography,
  styled,
} from '@matic.co/core'
import useNetwork from '@/hooks/useNetwork'

const TitleStyled = styled(MTypography)({
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: 10,
})

export const MOfflineNotification = () => {
  const { isOnline } = useNetwork()

  return (
    <MDialog
      open={!isOnline}
      hideCloseButton
      maxWidth="sm"
      aria-labelledby="offline-dialog-title"
      aria-describedby="offline-dialog-description"
    >
      <MDialogContent id="offline-dialog-description">
        <TitleStyled>You have lost your internet connection.</TitleStyled>
        <MGrid container justifyContent="center" alignContent="center">
          <MTypography sx={{ marginRight: 1 }} color="textSecondary">
            Reconnecting...
          </MTypography>
          <MSpinner size={20} />
        </MGrid>
      </MDialogContent>
    </MDialog>
  )
}
