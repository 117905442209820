import { styled, MTypography, MTooltip } from '@matic.co/core'

// Components
import { MAvatar } from '@/components/common/MAvatar'

// Types
import { TAccess } from '@/types/store/state'

// Styles
const ContainerStyled = styled('div')({
  position: 'relative',
})
const CardStyled = styled('div')({
  borderRadius: 12,
  margin: '0 20px 10px',
  padding: '18px 20px 18px 20px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'background-color 400ms ease-in-out',
})
const CardContainerStyled = styled('div')({
  marginLeft: 15,
  display: 'flex',
  width: '100%',
})
const MAvatarStyled = styled(MAvatar)(({ theme }) => ({
  boxShadow: theme.shadows[24],
  borderRadius: '999999px',
  border: '1px solid #ececec',
}))
const MTypographyStyled = styled(MTypography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.text.grayBluePrimary,
  minHeight: 21,
}))

export type ComponentProps = {
  /**
   * Access with the session started
   */
  access: TAccess
}

const ACCESS_NAME_MAX_LENGTH = 17
const MAX_LENGTH_WITHOUT_TRUNCATE = ACCESS_NAME_MAX_LENGTH + 3

export const MDrawerTitle = ({ access }: ComponentProps) => {
  const shouldTruncate = access.name.length >= MAX_LENGTH_WITHOUT_TRUNCATE

  return (
    <ContainerStyled>
      <CardStyled>
        <MAvatarStyled
          src={access.logo || '/images/company_default.svg'}
          size={40}
        />
        <CardContainerStyled>
          {shouldTruncate ? (
            <MTooltip title={access.name} arrow placement="right">
              <MTypographyStyled>
                {`${access.name.slice(0, ACCESS_NAME_MAX_LENGTH)}...`}
              </MTypographyStyled>
            </MTooltip>
          ) : (
            <MTypographyStyled>{access.name}</MTypographyStyled>
          )}
        </CardContainerStyled>
      </CardStyled>
    </ContainerStyled>
  )
}
