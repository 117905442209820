// Context
import { AuthProvider } from '@/context/authContext'
import { ConfigProvider } from '@/context/configContext'
import { MainProvider } from '@/context/mainContext'

export const AppProvider: React.FC = ({ children }) => (
  <MainProvider>
    <AuthProvider>
      <ConfigProvider>
        <>{children}</>
      </ConfigProvider>
    </AuthProvider>
  </MainProvider>
)
