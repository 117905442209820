import { useState, CSSProperties } from 'react'
import { styled, MSkeleton } from '@matic.co/core'

// Components
import { MImage } from '@/components/common/MImage'

// Styles
const ContainerStyled = styled('div')({
  position: 'relative',
})
const MImageStyled = styled(MImage)({
  '& img': {
    objectFit: 'cover',
    objectPosition: 'center',
  },
})
const LetterStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
}))
const MSkeletonStyled = styled(MSkeleton)({
  position: 'absolute',
  zIndex: 10,
  top: 0,
})

export type ComponentProps = {
  /**
   * The path or URL to the source image (The image must be in the same domain or in the domains configured in next.config.js)
   */
  src: string
  /**
   * letterImg is used when there is no image for the avatar, the image is placed with the primary background (if it exists, ignore src).
   */
  letterImg?: string
  /**
   * The width of the image, in pixels. Must be an integer without a unit
   */
  size: string | number
  /**
   * The size of the image, in pixels. Must be an integer without a unit
   */
  alt?: string
  /**
   * Is the image loading
   */
  isLoading?: boolean
  /**
   * Css class
   */
  className?: string
  /**
   * Style object
   */
  style?: CSSProperties
}

export const MAvatar = ({
  src,
  alt,
  size,
  letterImg,
  className,
  style,
  isLoading,
  ...other
}: ComponentProps) => {
  const [loadImage, setLoadImage] = useState<boolean>(!letterImg)

  const handleLoad = () => setLoadImage(false)

  return (
    <ContainerStyled>
      {(loadImage || isLoading) && (
        <MSkeletonStyled variant="circular" width={size} height={size} />
      )}

      {letterImg ? (
        <LetterStyled
          className={className}
          style={style}
          sx={{ height: size, width: size, borderRadius: size }}
        >
          {letterImg}
        </LetterStyled>
      ) : (
        <MImageStyled
          src={src}
          alt={alt}
          width={size}
          height={size}
          className={className}
          style={style}
          onLoad={handleLoad}
          sx={{
            width: size,
            height: size,
            '& img': {
              borderRadius: size,
            },
          }}
          {...other}
        />
      )}
    </ContainerStyled>
  )
}
