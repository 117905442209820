import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import {
  styled,
  MTabs,
  MTab,
  MTypography,
  useMediaQuery,
  MDivider,
} from '@matic.co/core'
import { TTab } from '@matic.co/utils'

// Component
import { MLink } from '@/components/common/MLink'

// Styles
const TabsContainerStyled = styled('div')({
  display: 'flex',
})
const TabsStyled = styled(MTabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    height: 2,
    backgroundColor: theme.palette.primary.light,
  },
}))

const TabStyled = styled(MTab)({
  '@media(max-width:585px)': {
    minWidth: 'auto !important',
    width: 'auto !important',
    flexShrink: 1,
    textAlign: 'left',
    overflow: 'visible',
    marginRight: 20,
  },
})

const DescriptionContainerStyled = styled('div')(({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  color: theme.palette.grey[600],
}))
const DescriptionDividerStyled = styled(MDivider)({
  marginTop: 5,
  backgroundColor: '#D8D9EA',
})
const ContentStyled = styled('div')({
  display: 'grid',
  marginTop: 30,
  position: 'relative',
})

export type ComponentProps = {
  /**
   * Array with the labels of the required tabs
   */
  tabs: TTab[]
  /**
   * Description that the page will have (right between the tabbar and the content)
   */
  description?: string
}

export const MTabsContent: React.FC<ComponentProps> = ({
  children,
  tabs,
  description,
}) => {
  const maxW768 = useMediaQuery('(max-width:768px)')
  const { pathname } = useLocation()

  const activeTab = useMemo(
    () => tabs.findIndex((tab) => tab.to === pathname),
    [pathname]
  )

  return (
    <>
      <TabsContainerStyled className="animate__animated animate__fadeIn">
        <TabsStyled
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={maxW768}
          value={activeTab}
        >
          {tabs.map((tab, i) => (
            <TabStyled
              label={
                <MTypography
                  variant="inherit"
                  color={activeTab == i ? 'primary' : 'textSecondary'}
                >
                  {tab.label}
                </MTypography>
              }
              component={MLink}
              to={tab.to}
              key={tab.to}
            />
          ))}
        </TabsStyled>
      </TabsContainerStyled>
      {description && (
        <DescriptionContainerStyled>
          <MTypography variant="caption">{description}</MTypography>
          <DescriptionDividerStyled />
        </DescriptionContainerStyled>
      )}

      <ContentStyled className="animate__animated animate__fadeIn">
        {children}
      </ContentStyled>
    </>
  )
}
