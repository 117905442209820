import {
  MCollapse,
  MList,
  MListItem,
  MListItemText,
  MTextField,
  MTooltip,
  MTypography,
  styled,
} from '@matic.co/core'
import {
  EvaArrowIosForwardOutline,
  EvaCheckmark,
  EvaSearchOutline,
} from '@matic.co/icons'

// Types
import { TAccess } from '@/types/store/state'
import { useSelectCompany } from '@/hooks/modules/navbar/useSelectCompany'

const ItemStyled = styled(MListItem)(({ theme }) => ({
  padding: '8px 10px 8px 20px',

  '&.active': {
    fontWeight: 600,
  },
  '& span': {
    width: '100%',
  },
  '&.active > span': {
    width: 'calc(100% - 18px)',
  },
  '& svg': {
    color: theme.palette.primary.main,
  },
}))

const TextStyled = styled(MListItemText)(({ theme }) => ({
  color: theme.palette.text.grayBluePrimary,

  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '&.textBold span': {
    fontWeight: 800,
  },
}))

const ArrowIcon = styled(EvaArrowIosForwardOutline)(({ theme }) => ({
  transition: 'transform 400ms ease-in-out',
  color: theme.palette.text.grayBlueSecundary,

  '&.open': {
    transform: ' rotate(90deg)',
    transition: 'transform 400ms ease-in-out',
  },
}))
const CompaniesList = styled(MList, {
  shouldForwardProp: (prop: string) => !['searchisvisible'].includes(prop),
})<{ searchisvisible: boolean }>(({ theme, searchisvisible }) => ({
  maxHeight: searchisvisible ? '185px' : 'none',
  overflow: searchisvisible ? 'auto' : 'hidden',
  '&::-webkit-scrollbar': {
    width: 4,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#fff',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.grey[600],
  },
}))

const SearchBar = styled(MTextField)({
  marginTop: 4,
  marginBottom: 4,

  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    fontSize: 12,
  },
})

export type ComponentProps = {
  /**
   * Flag that indicates if list is disabled
   */
  disabled?: boolean
  /**
   * List of accesses available for this session
   */
  accesses: TAccess[]
  /**
   * Access with the session started
   */
  initialAccess: TAccess
}

export const MSelectCompany = ({
  accesses,
  initialAccess,
  disabled,
}: ComponentProps) => {
  const { isOpen, searchValue, handleGoToTenant, handleSearch, toggleIsOpen } =
    useSelectCompany()

  const showSearch = accesses.length > 5

  const availableAccesses = searchValue
    ? accesses.filter(({ name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase())
      )
    : accesses

  return (
    <>
      <MTooltip title={initialAccess.name}>
        <MListItem
          button
          onClick={toggleIsOpen}
          disabled={disabled}
          sx={{
            padding: '8px 10px',
          }}
        >
          <TextStyled>{initialAccess.name}</TextStyled>
          <ArrowIcon size={20} className={isOpen ? 'open' : ''} />
        </MListItem>
      </MTooltip>
      <MCollapse in={isOpen}>
        {showSearch && (
          <SearchBar
            type="text"
            size="small"
            placeholder="Search a Company"
            value={searchValue}
            disabled={disabled}
            onChange={handleSearch}
            InputProps={{
              endAdornment: <EvaSearchOutline size={18} />,
            }}
          />
        )}
        <CompaniesList searchisvisible={showSearch} disablePadding>
          {availableAccesses.length > 0 ? (
            availableAccesses.map((currentAccess) => (
              <ItemStyled
                button
                key={currentAccess.id}
                onClick={() =>
                  handleGoToTenant(
                    initialAccess.id === currentAccess.id
                      ? '/'
                      : currentAccess.domain
                  )
                }
                className={`${
                  initialAccess.id === currentAccess.id && 'active'
                }`}
              >
                <MTooltip title={currentAccess.name}>
                  <TextStyled className="textBold">
                    {currentAccess.name}
                  </TextStyled>
                </MTooltip>
                {initialAccess.id === currentAccess.id && (
                  <EvaCheckmark size={18} style={{ marginLeft: 1 }} />
                )}
              </ItemStyled>
            ))
          ) : (
            <MTypography align="center" color="textSecondary" variant="body2">
              No results
            </MTypography>
          )}
        </CompaniesList>
      </MCollapse>
    </>
  )
}
