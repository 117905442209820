import { useState } from 'react'

// Components
import { MMerchantsList } from '@/components/common/MMerchantsList'

// Hooks
import { useAuth } from '@/hooks/modules/session/useAuth'

// Types
import { TMerchantAccess } from '@/types/store/state'

export type ComponentProps = {
  /**
   * Title merchant list
   */
  title?: string
  /**
   * className
   */
  className?: string
  /**
   * className container
   */
  classNameContainer?: string
  /**
   * className items container
   */
  classNameItems?: string
}

export const MMerchantsListAccess = ({
  title = '',
  className = '',
  classNameItems = '',
  classNameContainer = '',
}: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const {
    auth: { merchants, merchant: merchantActive },
    merchantAccesses: {
      handleGetList: handleLoadMerchants,
      handleSelect: handleSelectAccessMerchant,
    },
  } = useAuth()

  const handleClose = () => setOpen(false)

  const handleGetList = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await handleLoadMerchants()
      if (merchants.length) setOpen(true)
      setLoading(false)
    } catch (error) {
      return
    }
  }

  const handleSelect = ({ id }: TMerchantAccess) => {
    handleSelectAccessMerchant(id)
    setOpen(false)
  }

  return (
    <MMerchantsList
      handleOpen={handleGetList}
      handleClose={handleClose}
      handleSelect={handleSelect}
      merchants={merchants}
      merchantActive={merchantActive || undefined}
      open={open}
      loading={loading}
      className={className}
      classNameItems={classNameItems}
      classNameContainer={classNameContainer}
      title={title}
    />
  )
}
