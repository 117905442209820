import { useState, MouseEvent } from 'react'
import { styled, MButton } from '@matic.co/core'
import {
  EvaMenu2Outline,
  // EvaSearchOutline
} from '@matic.co/icons'

// Components
import { MAvatar } from '@/components/common/MAvatar'
import { MNavbarMenu } from '@/components/common/navbar/MNavbarMenu'
import { drawerWidth } from '@/components/common/drawer/MDrawer'

// Types
import { TAccess, TUser } from '@/types/store/state'

// Styles
const ContainerStyled = styled('div')({
  height: 58,
})
const NavStyled = styled('nav')(({ theme }) => ({
  width: '100%',
  height: 58,
  position: 'fixed',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 30px',
  zIndex: 100,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: '#fff',

  '&.openDrawer': {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}))
const MButtonStyled = styled(MButton)(({ theme }) => ({
  minWidth: 30,
  height: 30,
  color: theme.palette.text.grayBluePrimary,
}))
const MButtonAvatarStyled = styled(MButton)({
  minWidth: 28,
  height: 28,
  borderRadius: 28,
  padding: 0,
})

export type ComponentProps = {
  /**
   *  Callback fired when clicked
   */
  searchOnClick?: VoidFunction
  /**
   * Callback fired when menu button is clicked
   */
  menuOnClick?: VoidFunction
  /**
   * User with the session logged
   */
  user: TUser | null
  /**
   * Access with the session logged
   */
  access: TAccess
  /**
   * List of accesses available for this session
   */
  accesses: TAccess[]
  /**
   * If the navbar should adjust width to show Drawer
   */
  openDrawer?: boolean
  /**
   * Callback fired when log out
   */
  onLogout: () => void
  /**
   * Shows if the logout is loading
   */
  loadingLogout?: true | false
}

export const MNavbar = ({
  user,
  access,
  accesses,
  openDrawer,
  menuOnClick,
  onLogout,
  loadingLogout,
}: ComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClickMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)

  const handleCloseMenu = () => setAnchorEl(null)

  return (
    <ContainerStyled>
      <NavStyled className={`${openDrawer ? 'openDrawer' : ''}`}>
        <div>
          <MButtonStyled variant="text" color="white" onClick={menuOnClick}>
            <EvaMenu2Outline size={18} />
          </MButtonStyled>

          {/* <MButtonStyled
          variant="text"
          className="buttonSearch"
          onClick={searchOnClick}
        >
          <EvaSearchOutline size={18} />
        </MButtonStyled> */}
        </div>

        <MButtonAvatarStyled
          variant="text"
          onClick={handleClickMenu}
          disabled={!user}
        >
          {user?.photo ? (
            <MAvatar src={user?.photo || '/images/noImage.svg'} size={30} />
          ) : (
            <MAvatar
              src=""
              letterImg={
                user?.first_name
                  ? `${user?.first_name[0]}${user?.last_name[0]}`
                  : user?.email[0]
              }
              size={30}
            />
          )}
        </MButtonAvatarStyled>

        <MNavbarMenu
          user={user}
          access={access}
          accesses={accesses}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          onLogout={onLogout}
          loadingLogout={loadingLogout}
        />
      </NavStyled>
    </ContainerStyled>
  )
}
