import { getTime } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useToasts } from '@matic.co/core'

import { useAuth } from '@/hooks/modules/session/useAuth'
import { getCookie, removeCookie } from '@/utils/cookies'

type THookParams = {
  isOpenFastLogin?: boolean
  timeout?: number
  throttle?: number
}

const INACTIVITY_WARNING_TOAST_KEY = 'matic-inactivity-info-toast'

export const useUserActivity = (params?: THookParams) => {
  const { addToast, removeToast } = useToasts()
  const [showToastWarning, setShowToastWarning] = useState(false)
  const [refreshSuccess, setRefreshSuccess] = useState(false)
  const userRef = useRef<boolean>(false)
  const refreshingRef = useRef<boolean>(false)
  const {
    auth: { user, handleRefreshToken },
  } = useAuth()

  const [remaining, setRemaining] = useState<number>(0)

  const { getRemainingTime, isIdle } = useIdleTimer({
    timeout: params?.timeout ?? 10_000,
    throttle: params?.throttle ?? 1000,
  })

  const handleRefreshTokenInterval = async () => {
    setRemaining(Math.ceil(getRemainingTime() / 1000))

    const expiresTime = Number(getCookie('in_a_exp'))
    const currentTime = getTime(new Date())

    if (currentTime > expiresTime) {
      if (userRef.current && !refreshingRef.current) {
        if (isIdle()) {
          setShowToastWarning(true)
        } else {
          const existAccessToken = Boolean(getCookie('in_a'))

          if (!refreshingRef.current && existAccessToken) {
            refreshingRef.current = true

            handleRefreshToken()
              .then(() => {
                setRefreshSuccess(true)
              })
              .catch(() => {
                removeCookie('in_a')

                addToast(
                  'An error occurred while trying to extend the session',
                  {
                    appearance: 'error',
                  }
                )
              })
              .finally(() => {
                setShowToastWarning(false)
                refreshingRef.current = false
              })
          } else {
            setShowToastWarning(false)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (showToastWarning && !params?.isOpenFastLogin) {
      addToast('Your session is about to end due to inactivity', {
        key: INACTIVITY_WARNING_TOAST_KEY,
        appearance: 'warning',
        autoDismiss: false,
      })
    } else {
      removeToast(INACTIVITY_WARNING_TOAST_KEY)
    }
  }, [showToastWarning])

  useEffect(() => {
    if (refreshSuccess) {
      addToast('Your session has been extended', {
        appearance: 'success',
      })

      setRefreshSuccess(false)
    }
  }, [refreshSuccess])

  useEffect(() => {
    userRef.current = Boolean(user)
  }, [user])

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshTokenInterval()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return {
    isActive: !isIdle(),
    remaining,
  }
}
