import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NProgress from 'nprogress'

// Hooks
import { useConfig } from '@/hooks/useConfig'
import { useAuth } from '@/hooks/modules/session/useAuth'
import { MOfflineNotification } from './components'

export const MAppProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { loadInitialConfig } = useConfig()
  const {
    auth: { user },
    logout: { onLogout },
  } = useAuth()

  const initLoad = async () => {
    try {
      await loadInitialConfig()
    } catch (error) {
      onLogout()
    }
  }

  useEffect(() => {
    if (user) {
      initLoad()
    }
  }, [user])

  useEffect(() => {
    NProgress.done()

    return () => {
      NProgress.start()
    }
  }, [pathname])

  return (
    <>
      {children}
      <MOfflineNotification />
    </>
  )
}
