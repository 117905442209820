import { CSSProperties } from 'react'

export type ComponentProps = {
  /**
   * The path or URL to the source image (The image must be in the same domain or in the domains configured in next.config.js)
   */
  src: string
  /**
   * The width of the image, in pixels. Must be an integer without a unit
   */
  width: string | number
  /**
   * The height of the image, in pixels. Must be an integer without a unit
   */
  height: string | number
  /**
   * Specifies an alternate text for an image, if the image cannot be displayed
   */
  alt?: string
  /**
   * Css class
   */
  className?: string
  /**
   * Style object
   */
  style?: CSSProperties
  /**
   * Event that is executed when loading the image
   */
  onLoad?: VoidFunction
}

export const MImage = ({
  src,
  alt,
  width,
  height,
  className,
  style,
  ...other
}: ComponentProps) => (
  <div className={className} style={style}>
    <img src={src} alt={alt} width={width} height={height} {...other} />
  </div>
)
