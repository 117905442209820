import {
  MDrawer as Drawer,
  MButton,
  MList,
  MListItem,
  MListItemBadge,
  MListItemIcon,
  MListItemText,
  MTooltip,
  styled,
} from '@matic.co/core'
import {
  EvaAlertTriangleOutline,
  EvaArrowIosBackOutline,
} from '@matic.co/icons'
import { useLocation } from 'react-router-dom'

// Components
import { MLink } from '@/components/common/MLink'
import { MDrawerTitle } from '@/components/common/drawer/MDrawerTitle'
import { MMerchantsListAccess } from '@/components/containers/MMerchantsListAccess'

// Utils
import { getMainPath } from '@/utils/getMainPath'

// Types
import { TDrawerItems } from '@/types/TDrawer'
import { TAccess } from '@/types/store/state'

export const drawerWidth = 280

// Styles
const ContainerNavStyled = styled('nav')<any>(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}))
const DrawerHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 5,

  '& .Mui-paper': {
    width: drawerWidth,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[100],
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}))
const ButtonCloseStyled = styled(MButton)(({ theme }) => ({
  margin: 15,
  minWidth: 20,
  height: 20,
  padding: 0,
  color: theme.palette.text.grayBlueSecundary,
}))
const ListItemStyled = styled(MListItem)({
  paddingRight: 16,
})
const IconStyled = styled(MListItemIcon)(({ theme }) => ({
  width: 20,
  justifyContent: 'center',
  '&.alert': {
    color: theme.palette.error.main,
  },
  merchantListContainer: {
    margin: '0 25px 10px',
  },
  merchantList: {
    width: '100%!important',
  },
}))
const TooltipStyled = styled(MTooltip)({
  display: 'flex',
})
const MerchantsListContainerStyled = styled('div')({
  marginLeft: 20,
})

export type ComponentProps = {
  /**
   * Drawer menu items
   */
  itemsMenu: TDrawerItems[]
  /**
   * Open drawer
   */
  open: true | false
  /**
   * Defines when to make the drawer "temporary" or "permanent"
   */
  isMobile?: true | false
  /**
   * Callback fired when the component requests to be closed
   */
  onClose?: VoidFunction
  /**
   * Access with the session started
   */
  access: TAccess
  /**
   * List of accesses available for this session
   */
  accesses: TAccess[]
}

export const MDrawer = ({
  itemsMenu,
  open,
  isMobile,
  onClose,
  access,
}: ComponentProps) => {
  const { pathname } = useLocation()

  return (
    <ContainerNavStyled
      sx={{
        display: !isMobile && !open ? 'contents' : 'block',
      }}
    >
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        onClose={onClose}
        open={open}
      >
        <DrawerHeaderStyled>
          {isMobile && (
            <ButtonCloseStyled variant="text" onClick={onClose}>
              <EvaArrowIosBackOutline size={20} />
            </ButtonCloseStyled>
          )}
        </DrawerHeaderStyled>
        <MLink to="/">
          <MDrawerTitle access={access} />
        </MLink>

        {
          <MerchantsListContainerStyled>
            <MMerchantsListAccess title="Switch Merchant Account" />
          </MerchantsListContainerStyled>
        }

        <MList>
          {itemsMenu.map((item: TDrawerItems, i) => (
            <ListItemStyled
              button={true}
              component={MLink}
              to={item.href}
              selected={!!item.href && getMainPath(item.href, pathname)}
              key={i}
            >
              <IconStyled>
                <i className={`matic-${item.icon}`}>
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>
              </IconStyled>
              <MListItemText>{item.label}</MListItemText>
              {!!item.errors && (
                <MListItemBadge color="error" value={item.errors} />
              )}
              {item.alerts && (
                <TooltipStyled title={item.alerts} placement="bottom">
                  <IconStyled className="alert">
                    <EvaAlertTriangleOutline />
                  </IconStyled>
                </TooltipStyled>
              )}
            </ListItemStyled>
          ))}
        </MList>
      </Drawer>
    </ContainerNavStyled>
  )
}
