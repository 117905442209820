export const ENV_PROD = import.meta.env ? import.meta.env.PROD : false

export const APP_RELEASE = import.meta.env
  ? import.meta.env.VITE_APP_RELEASE?.toString()
  : ''

export const APP_ENVIRONMENT = import.meta.env
  ? import.meta.env.VITE_APP_ENVIRONMENT?.toString()
  : 'local'

export const DOMAIN_COOKIE = import.meta.env
  ? import.meta.env.VITE_DOMAIN_COOKIE?.toString()
  : ''

export const API_URL = import.meta.env
  ? import.meta.env.VITE_API_URL?.toString()
  : ''

export const ACCOUNT_URL = import.meta.env
  ? import.meta.env.VITE_ACCOUNT_URL?.toString()
  : 'https://account.matic.io'

export const SPREEDLY_ENVIRONMENT_KEY = import.meta.env
  ? import.meta.env.VITE_SPREEDLY_ENVIRONMENT_KEY?.toString()
  : ''

export const SENTRY_DSN = import.meta.env
  ? import.meta.env.VITE_SENTRY_DSN?.toString()
  : ''
export const SENTRY_ENABLED = import.meta.env
  ? import.meta.env.VITE_SENTRY_ENABLED === 'true'
  : false

export const RECAPTCHA_KEY = import.meta.env
  ? import.meta.env.VITE_RECAPTCHA_KEY?.toString()
  : ''

export const ENCRYPTION_KEY = import.meta.env
  ? import.meta.env.VITE_ENCRYPTION_KEY?.toString().replace(/\\n/g, '\n')
  : ''
