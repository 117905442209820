import { createContext, useContext, useReducer } from 'react'

// Reducers
import { mainReducer, initialConfigState } from './reducers/mainReducer'

// Types
import { TContextProps } from '@/types/TContext'
import { TMain } from '@/types/store/state'

export const MainContextt = createContext<TContextProps<TMain>>([
  initialConfigState,
  () => undefined,
])

export type TMainContexttProps = {
  /**
   * Set initial state
   * @default initialConfigState
   */
  initialState?: TMain
}
export const MainProvider: React.FC<TMainContexttProps> = ({
  children,
  initialState = initialConfigState,
}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <MainContextt.Provider value={[state, dispatch]}>
      {children}
    </MainContextt.Provider>
  )
}

export const useMainContext = () => useContext(MainContextt)
