import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

// Utils
import { getHeadTitle } from '@/utils/getHeadTitle'

export type ComponentProps = {
  /**
   * Title that will be displayed in the <title /> tag in the <head> of the application
   */
  title?: string
}

export const MHead = ({ title }: ComponentProps) => {
  const { pathname } = useLocation()

  const headTitle = title || getHeadTitle(pathname)

  return <Helmet title={headTitle ? `${headTitle} | Matic` : 'Matic'} />
}
